import { render, staticRenderFns } from "./touBaoAnLan.vue?vue&type=template&id=fbb9bf82&scoped=true&"
import script from "./touBaoAnLan.vue?vue&type=script&lang=js&"
export * from "./touBaoAnLan.vue?vue&type=script&lang=js&"
import style0 from "./touBaoAnLan.vue?vue&type=style&index=0&id=fbb9bf82&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbb9bf82",
  null
  
)

export default component.exports